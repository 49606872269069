export async function importApp(app, shareScope, importedApps) {
  let appParts = getAppParts(app)
  
  // eslint-disable-next-line no-undef
  return System.import(appParts.app).then(app => {

    if (!importedApps.includes(appParts.app)) {
      app.init(shareScope)
      importedApps.push(app)
    }
    
    if (appParts.module) {
      return app.get(appParts.module).then(module => {
        return module()
      })
    } else {
      return app
    }
  })
}
  
function getAppParts(app) {
  let appParts = app.split('/')

  if (app.startsWith('@')) {
    let lastIndex = app.lastIndexOf('/')
    let module = app.substring(lastIndex + 1)
    let application = app.slice(0, lastIndex)
    return {
      app: application,
      module: module
    }
  }

  return {
    app: appParts[0],
    module: appParts[1]
  }
}
